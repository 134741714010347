import './App.css';
import React, { useEffect, useState } from 'react';
import './assets/css/style.css'

import { isReduxStoredCleared } from './storage';
import { useCustomDispatch, useCustomLocation, useCustomNavigate } from './hooks';

import { useAuthSelector } from './services/selectors/authSelector';
import { authToken } from './storage/authToken';
import { fetchPharmacyDetails } from './services/apis/dispense/getPharmacyDetail';

import { SendMessageNotification } from './components/chat/helper';
import { NewMessageNotification } from './components/common/AllConfirmationMessages';
import { ChatBox } from './components/chat/chatBox';
import { chatNotificationAudio } from './assets/audio';

import * as routeNames from './routes/routeName';
import * as EndPoints from './networking/urlEndPoints'

import AppRoutes from './routes/appRoutes';
import Cookies from 'js-cookie';

import ActivityTracker from './components/chat/userActivityStatus';
import Ably from 'ably';

function App() {

	const { pharmacyId, authPharmacistId } = useAuthSelector()

	const location = useCustomLocation();
	const navigate = useCustomNavigate();
	const dispatch = useCustomDispatch();

	const token = authToken()

	useEffect(() => {
		if (isReduxStoredCleared) {
			Cookies.remove('authToken')
			navigate(routeNames.HOME_ROUTE)
		}
	}, [])

	const [isOnline, setIsOnline] = useState(window.navigator.onLine);
	const [showNotification, setShowNotification] = useState(false)
	const [showData, setShowData] = useState(null)

	useEffect(() => {
		const handleOnlineStatusChange = () => {
			setIsOnline(window.navigator.onLine);
		};

		window.addEventListener('online', handleOnlineStatusChange);
		window.addEventListener('offline', handleOnlineStatusChange);

		return () => {
			window.removeEventListener('online', handleOnlineStatusChange);
			window.removeEventListener('offline', handleOnlineStatusChange);
		};
	}, []);

	useEffect(() => {

		const ably = new Ably.Realtime({ key: EndPoints.apiKey });
		const channel = ably.channels.get('oat-pharmacy-inspection-mode');

		channel.subscribe(`inspection-${pharmacyId}`, (message) => {
			if (message.name === `inspection-${pharmacyId}` && pharmacyId && token) {
				dispatch(fetchPharmacyDetails(pharmacyId))
			}
		})

		return () => {
			if (ably.connection.state === 'connected') {
				ably.close();
			}
		}
	}, [pharmacyId, dispatch, token]);

	useEffect(() => {
		window.addEventListener('scroll', () => {
			const metaTag = document.querySelector('meta[name="theme-color"]');
			if (window.scrollY / window.innerHeight > 0.8) {
				metaTag.setAttribute("content", "#FDF8F7");
			} else {
				metaTag.setAttribute("content", "#FDF8F7");
			}
		}, false)
	}, [pharmacyId])

	const playMessageSound = () => {
		const audio = document.getElementById('notification_audio');
		audio.play();
	}

	useEffect(() => {
		const ably = new Ably.Realtime({ key: EndPoints.apiKey });
		const ablyConnection = SendMessageNotification(pharmacyId, authPharmacistId, dispatch, location, setShowData, setShowNotification, playMessageSound)

		return () => {
			if (ably.connection.state === 'connected') {
				ablyConnection.close();
			}
		};

	}, [pharmacyId, authPharmacistId, dispatch, location]);

	return (
		<>
			{!isOnline && (
				<div className="fixed inset-0 flex items-center justify-center z-50 backdrop-filter backdrop-blur-sm">
					<div className="bg-white p-6 rounded-lg shadow-lg">
						<div className="flex items-center mb-4">
							<svg className="w-6 h-6 mr-3 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
							</svg>
							<p className="font-semibold text-lg">Connection Lost</p>
						</div>
						<p>Please check your internet connection.</p>
					</div>
				</div>)}

			<AppRoutes />

			{location.pathname === '/verify-otp' ? null :
				token && pharmacyId && (
					<>
						{location.pathname !== '/initated-chat' && <ChatBox />}
						<ActivityTracker />
						{!location.pathname.startsWith('/initated-chat') && (
							<div className={`fixed w-full left-0 duration-500 ${showNotification ? 'top-0' : '-top-full'}`}>
								<NewMessageNotification
									message={`${showData?.title} sent you a message`}
									data={showData}
									setShowToast={() => setShowNotification(false)}
								/>
							</div>
						)}
					</>
				)}


			<audio id="notification_audio">
				<source src={chatNotificationAudio} type="audio/mpeg" />
			</audio>

		</>
	);
}

export default App;
