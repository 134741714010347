import React from 'react';

import { Route, Routes } from 'react-router-dom';
import { authOTPVerified, authToken } from '../storage/authToken';
import { ToastContainer } from 'react-toastify';
import { useAuthSelector } from '../services/selectors/authSelector';

import * as Auth from '../auth';
import * as Pages from '../pages';
import * as routeNames from './routeName';

import ProtectedRoute from './protectedRoute';
import ChatInterface from '../components/chat/initatedChatBox';

const AppRoutes = () => {

	const token = authToken();

	const { pharmacyId, send_driver_delivery_otp } = useAuthSelector()
	const verifiedOtp = Number(authOTPVerified())

	return (

		<>
			<Routes>
				{!token && <>
					<Route exact path={routeNames.HOME_ROUTE} element={<Auth.SignIn />} />
				</>}

				<Route exact path={'initated-chat'} element={<ChatInterface />} />


				{pharmacyId && send_driver_delivery_otp === 1 &&
					<Route exact path={routeNames.verify_otp} element={<Auth.DriverOtpVerification />} />}

				{pharmacyId && (verifiedOtp === 1 || verifiedOtp === 0) ? <>

					<Route exact path={`${routeNames.DASHBOARD_PHARMACY}`} element={<ProtectedRoute Component={Pages.DriverDashboard}></ProtectedRoute>} />
					<Route exact path={`${routeNames.DRIVER_ROUTE_TODAY}`} element={<ProtectedRoute Component={Pages.TodayDispense}></ProtectedRoute>} />

					<Route exact path={`${routeNames.PHARMACY_FRONT_PROFILE}`} element={<ProtectedRoute Component={Pages.PharmacyFrontProfile}></ProtectedRoute>} />

					<Route exact path={routeNames.DRIVER_PASSWORD} element={<ProtectedRoute Component={Pages.ChangePassword}></ProtectedRoute>} />
					<Route exact path={`${routeNames.MY_PROFILE}`} element={<ProtectedRoute Component={Pages.MyProfile}></ProtectedRoute>} />
					<Route exact path={routeNames.DRIVER_PASSCODE} element={<ProtectedRoute Component={Pages.UpdatePasscode}></ProtectedRoute>} />

					<Route exact path={`${routeNames.Patient_Consent}`} element={<ProtectedRoute Component={Pages.PatientConsent}></ProtectedRoute>} />
					<Route exact path={`${routeNames.Missed_Route}`} element={<ProtectedRoute Component={Pages.MissedDeliveryPatient}></ProtectedRoute>} />
					<Route exact path={`${routeNames.driver_Missed_Route}`} element={<ProtectedRoute Component={Pages.FinalMissedDelivery}></ProtectedRoute>} />

					<Route exact path={`${routeNames.DRIVER_ROUTE_DELIVERED}`} element={<ProtectedRoute Component={Pages.DeliveredPatient}></ProtectedRoute>} />
					<Route exact path={`${routeNames.driver_today_pending}`} element={<ProtectedRoute Component={Pages.PendingDispense}></ProtectedRoute>} />
					<Route exact path={`${routeNames.search_patient_data}`} element={<ProtectedRoute Component={Pages.SearchPatientList}></ProtectedRoute>} />

					<Route exact path={`${routeNames.upload_prescripition}`} element={<ProtectedRoute Component={Pages.UploadPrescripition}></ProtectedRoute>} />

					<Route exact path={`${routeNames.pending_pharmacist_signatures}`} element={<ProtectedRoute Component={Pages.PendingPharmacistSignatures}></ProtectedRoute>} />


				</> :

					verifiedOtp === 0 && pharmacyId && token ?
						<Route exact path={routeNames.verify_otp} element={<Auth.DriverOtpVerification />} /> :

						pharmacyId && token && send_driver_delivery_otp === 1 ?
							<Route exact path={routeNames.verify_otp} element={<Auth.DriverOtpVerification />} />
							:
							<Route path="*" element={<Auth.SignIn />} />
				}


				{token && pharmacyId ?
					<Route path="*" element={<Pages.DriverDashboard />} />
					:
					token && pharmacyId && verifiedOtp === 0 && send_driver_delivery_otp === 1 ?
						<Route path="*" element={<Auth.DriverOtpVerification />} />
						:
						token && pharmacyId ?
							<Route path="*" element={<Auth.DriverOtpVerification />} />
							:
							<Route path="*" element={<Auth.SignIn />} />
				}

			</Routes>
			<ToastContainer />
		</>
	)
}

export default AppRoutes