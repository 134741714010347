import React, {
	useEffect,
	useState
} from 'react';

import {
	useNavigate,
} from 'react-router-dom';

import {
	ErrorMessages,
	SuccessMessage
} from '../../components/error/errorMessages';

import { updateProfile } from '../../services/apis/setting/updateProfile';
import { useAllSelector } from '../../services/selectors/allSelector';
import { useAuthSelector } from '../../services/selectors/authSelector';

import ThemeTextInput from '../../components/form/textInput';
import ThemeButton from '../../components/form/button';
import ChangePasscode from './changePasscode';
import ChangePassword from './changePassword';
import avatar from '../../assets/images/usericon.jpeg';

import * as Icons from '../../assets/svg/icons';
import * as Hooks from '../../hooks';
import * as routeNames from '../../routes/routeName';

const MyProfile = () => {

	const dispatch = Hooks.useCustomDispatch();
	const { updateProfileResponse } = useAllSelector();
	const { authLoginResponse } = useAuthSelector();

	const userProfile = authLoginResponse?.data?.data?.pharmacist_profile;

	const [errorMessage, setErrorMessage] = useState(null);
	const [selectedImage, setSelectedImage] = useState(userProfile?.avatar || avatar);
	const [selectedImageFile, setSelectedImageFile] = useState(null);
	
	const [profileData, setProfileData] = useState(
		{
			name: userProfile?.name || "",
			email: userProfile?.email || "",
			phone: userProfile?.phone || "",
		}
	);

	const navigate = useNavigate();

	const displaySelectedImage = (event) => {
		if (event.target.files.length > 0) {
			const file = event.target.files[0];
			setSelectedImageFile(file);
			if (file.type.startsWith('image/')) {
				const reader = new FileReader();

				reader.onload = function (e) {
					setSelectedImage(e.target.result);
				};

				reader.readAsDataURL(file);
			} else {
				alert('Please select an image file.');
			}
		}
	}

	const handleSubmit = async (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);
		if (selectedImageFile)
			formData.append('avatar', selectedImageFile);
		const { name, email, phone } = profileData || {};
		try {
			if (!name || !email || !phone) {
				setErrorMessage('Please Complete your all field')
			} else {
				setErrorMessage(null)
				await dispatch(updateProfile(formData))
			}
		} catch (error) {
		}
	}

	useEffect(() => {
		if (updateProfileResponse?.error) {
			var errorMsg = "";
			const error = updateProfileResponse?.error?.data?.data?.error;
			Object.values(error).map(err => {
				if (typeof err === "string") {
					errorMsg += err + " ";
				} else {
					errorMsg += err.join(" ");
				}
			})
			setErrorMessage(errorMsg);
		} else {
			setErrorMessage(null);
		}
	}, [updateProfileResponse])

	useEffect(() => {
		if (userProfile) {
			setProfileData({
				name: userProfile?.name || "",
				email: userProfile?.email || "",
				phone: userProfile?.phone || "",
			})
			setSelectedImage(userProfile?.user_photo || avatar)
		}
		return () => { }
	}, [userProfile])

	return (
		<>
			<div className="app-wrapper flex justify-center h-screen">
				{/* <div className="app-screen no-shades w-full xl:w-3/12 lg:w-4/12 md:w-5/12 sm:w-6/12 h-full overflow-x-hidden "> */}
				{/* <div className="app-screen no-shades w-full  lg:w-full md:w-6/12 sm:w-6/12 h-full  overflow-x-hidden "> */}
				<div className="app-screen no-shades w-full xl:w-3/12 lg:w-4/12 md:w-5/12 sm:w-6/12 h-full overflow-x-hidden">

					{/* <div className="app-screen no-shades w-full xl:w-6/12 lg:w-4/12 md:w-6/12 sm:w-8/12 h-full md:h-auto overflow-y-hidden md:overflow-y-auto overflow-x-hidden"> */}

					<div className="relative p-4">
						<div className="title-row mb-5 flex items-center justify-between">
							<div>
								<h1 className="text-2xl font-bold">Profile Setting</h1>
							</div>
							<div className='flex items-center justify-center btnnn pt-4' onClick={() => navigate(`${routeNames.DASHBOARD_PHARMACY}`)}>
								<Icons.HomeIcon />
							</div>
						</div>
						<div className="card bg-white p-4 rounded-lg shadow-sm">
							<div className='flex flex-col items-center'>
								<div style={{ width: 80, height: 80 }}>
									<img className='w-full h-full rounded-md object-cover' src={selectedImage} alt='avatar' />
								</div>
								<button onClick={() => {
									const filePicker = document.getElementById('selectProfileImage');
									filePicker.click();
								}} className='text-sm text-blue-700 font-medium p-2'>Change Picture</button>
								<input name='avatar' onChange={displaySelectedImage} type='file' id="selectProfileImage" className='hidden' />
								<form noValidate encType='multipart/form-data' onSubmit={handleSubmit} className='w-full'>
									<ThemeTextInput

										label='Name'
										icon={() => (<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
											<g opacity="0.5">
												<path d="M9.99967 10.5002C9.08301 10.5002 8.29829 10.1738 7.64551 9.521C6.99273 8.86822 6.66634 8.0835 6.66634 
												7.16683C6.66634 6.25016 6.99273 5.46544 7.64551 4.81266C8.29829 4.15988 9.08301 3.8335 9.99967 3.8335C10.9163 
												3.8335 11.7011 4.15988 12.3538 4.81266C13.0066 5.46544 13.333 6.25016 13.333 7.16683C13.333 8.0835 13.0066 8.86822 12.3538 
												9.521C11.7011 10.1738 10.9163 10.5002 9.99967 10.5002ZM3.33301 15.5002V14.8335C3.33301 14.3613 3.45454 13.9272 3.69759 
												13.5314C3.94065 13.1356 4.26356 12.8335 4.66634 12.6252C5.52745 12.1946 6.40245 11.8717 7.29134 11.6564C8.18023 11.4411 
												9.08301 11.3335 9.99967 11.3335C10.9163 11.3335 11.8191 11.4411 12.708 11.6564C13.5969 11.8717 14.4719 12.1946 15.333 
												12.6252C15.7358 12.8335 16.0587 13.1356 16.3018 13.5314C16.5448 13.9272 16.6663 14.3613 16.6663 14.8335V15.5002C16.6663 
												15.9585 16.5031 16.3509 16.1768 16.6772C15.8504 17.0036 15.458 17.1668 14.9997 17.1668H4.99967C4.54134 17.1668 4.14898 
												17.0036 3.82259 16.6772C3.4962 16.3509 3.33301 15.9585 3.33301 15.5002Z" fill="#1B2336" />
											</g>
										</svg>)
										}
										name='name'
										value={profileData?.name}
										onChange={(e) => setProfileData({ ...profileData, name: e.target.value })}

									/>
									<ThemeTextInput
										label='Email address'
										icon={() => (<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
											<g opacity="0.5">
												<path d="M3.33366 17.1668C2.87533 17.1668 2.48296 17.0036 2.15658 16.6772C1.83019 16.3509 1.66699 15.9585 1.66699 15.5002V5.50016C1.66699 5.04183 1.83019 4.64947 2.15658 4.32308C2.48296 3.99669 2.87533 3.8335 3.33366 3.8335H16.667C17.1253 3.8335 17.5177 3.99669 17.8441 4.32308C18.1705 4.64947 18.3337 5.04183 18.3337 5.50016V15.5002C18.3337 15.9585 18.1705 16.3509 17.8441 16.6772C17.5177 17.0036 17.1253 17.1668 16.667 17.1668H3.33366ZM10.0003 11.1877C10.0698 11.1877 10.1427 11.1772 10.2191 11.1564C10.2955 11.1356 10.3684 11.1043 10.4378 11.0627L16.3337 7.37516C16.4448 7.30572 16.5281 7.21891 16.5837 7.11475C16.6392 7.01058 16.667 6.896 16.667 6.771C16.667 6.49322 16.5489 6.28488 16.3128 6.146C16.0767 6.00711 15.8337 6.01405 15.5837 6.16683L10.0003 9.66683L4.41699 6.16683C4.16699 6.01405 3.92394 6.01058 3.68783 6.15641C3.45171 6.30225 3.33366 6.50711 3.33366 6.771C3.33366 6.90988 3.36144 7.03141 3.41699 7.13558C3.47255 7.23975 3.55588 7.31961 3.66699 7.37516L9.56283 11.0627C9.63227 11.1043 9.70519 11.1356 9.78158 11.1564C9.85796 11.1772 9.93088 11.1877 10.0003 11.1877Z" fill="#1B2336" />
											</g>
										</svg>
										)
										}
										name='email'
										value={profileData?.email}
										type='email'
										onChange={(e) => setProfileData({ ...profileData, email: e.target.value })}

									/>
									<ThemeTextInput
										label='Phone'
										icon={() => (<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
											<g opacity="0.6">
												<path d="M16.625 18C14.8889 18 13.1736 17.6215 11.4792 16.8646C9.78472 16.1076 8.24306 15.0347 6.85417 13.6458C5.46528 12.2569 4.39236 10.7153 3.63542 9.02083C2.87847 7.32639 2.5 5.61111 2.5 3.875C2.5 3.625 2.58333 3.41667 2.75 3.25C2.91667 3.08333 3.125 3 3.375 3H6.75C6.94444 3 7.11806 3.06597 7.27083 3.19792C7.42361 3.32986 7.51389 3.48611 7.54167 3.66667L8.08333 6.58333C8.11111 6.80556 8.10417 6.99306 8.0625 7.14583C8.02083 7.29861 7.94444 7.43056 7.83333 7.54167L5.8125 9.58333C6.09028 10.0972 6.42014 10.5938 6.80208 11.0729C7.18403 11.5521 7.60417 12.0139 8.0625 12.4583C8.49306 12.8889 8.94444 13.2882 9.41667 13.6562C9.88889 14.0243 10.3889 14.3611 10.9167 14.6667L12.875 12.7083C13 12.5833 13.1632 12.4896 13.3646 12.4271C13.566 12.3646 13.7639 12.3472 13.9583 12.375L16.8333 12.9583C17.0278 13.0139 17.1875 13.1146 17.3125 13.2604C17.4375 13.4062 17.5 13.5694 17.5 13.75V17.125C17.5 17.375 17.4167 17.5833 17.25 17.75C17.0833 17.9167 16.875 18 16.625 18Z" fill="#1B2336" />
											</g>
										</svg>

										)
										}
										name='phone'
										value={profileData?.phone}
										type='phone'
										inputMode="numeric"
										onChange={(e) => setProfileData({ ...profileData, phone: e.target.value })}
									/>
									{
										errorMessage ?
											<ErrorMessages error={errorMessage} />
											: updateProfileResponse?.data?.success === true ?
												<SuccessMessage error="Profile updated successfully" />
												: null

									}
									<div className='mt-5'>
										<ThemeButton
											text='Update Profile'
											type='submit'
											isProcessing={updateProfileResponse?.loading}
										/>
									</div>
								</form>
							</div>
						</div>
						<ChangePassword />
						<ChangePasscode />
					</div>
				</div>
			</div>
		</>
	)
}

export default MyProfile
